body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.NavBar {
  width: 100vw;
}

.About {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10vh;
  height: 50vh;
  align-items:center;
}

.Accordian {
  width: 80vw;
}

.AboutHeader {
  width: 50vw;
}

h1 {
  padding: 50px;
  width: 45vw;
}

.sectionHeader {
  padding: 10px;
  padding-top: 10vh;
}

.ProjectData {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-evenly;
  align-items:baseline;
  height: auto;
  /* background-color: aliceblue; */
}

.ProjectCard {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 50vw;
  flex-wrap: wrap;
  min-width: 250px;
  padding-bottom: 5vh;
}

.ProjectImage {
  height: 4vw;
  max-width: 5vw;
  /* margin: 25px; */
}

.ProjectTitle {
  padding-top: 20px;
  padding-bottom: 25px;
  /* width: 20vw; */
  /* background-color: aliceblue; */
  /* height: 10vh; */
}

.ProjectBody {
  width: 33vw;
  /* font-size:1.4vw; */
  /* background-color: aliceblue; */
  text-align:center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.footer {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  padding-bottom: 10vh;
  /* height: 80vh; */
  /* font-size:1.5vw; */
  /* width: 50vw; */
  align-items:center;
  /* background-color: aliceblue; */
  flex-wrap: wrap;
  /* padding-left: 25vw; */
  /* padding-right: 25vw; */
}

.home {
  display: flex;
}

.me {
  /* max-width: 100%; */
  height: 40vw;
  /* object-fit: cover; */
}

.brown {
  height: 3vw;
}

h1 {
  font-size:3vw;
  width: 50vw;
  text-align: left;
  padding: 0%;
  padding-left: 3vw;
}

h2 {
  font-size: 1vw;
  width: 50vw;
  text-align: start;
}

p {
  text-align: start;
  padding-left: 3vw;
  padding-right: 3vw;
}

.icon {
  height: 5vw;
}

.icons {
  margin: 4vh;
  display: flex;
  justify-content: space-between;
  width: 40vw;
}

.logo {
  width: 15vw;
}

.ExperienceItem {
  display: flex;
  /* background-color: aliceblue; */
  align-items: center;
  /* width: 80vw; */
  justify-content: space-between;
  margin: 7.5vw;
  text-align: left;
  height: 15vh;
}